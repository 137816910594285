import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import styles from './shared.module.css'

const PurposePage = () => (
  <Layout>
    <SEO title="Purpose" />
    <div className={styles.introContainer}>
			<h1 className={styles.staticTitle}>
				Our <br/>Purpose
			</h1>
		</div>

		<div className={styles.content}>

			<div className={styles.purposeContainer}>
				<div className={styles.contentContainer}>
				<p>We are a London based studio who loves creating digital products with a purpose. From research and planning to custom design and development, we help startups and businesses achieve their goals and score big.</p>
				<p>We want to give ambitious startups and entrepreneurs the chance to focus on the things that matter most to them and give them the opportunity to make everyone as excited about their idea as they are themselves.</p>
				<p>We love what we do, but we believe that the magic is in collaboration. Working together with people who are passionate, committed and {"aren't"} afraid to get their hands dirty, we believe we can go that extra mile by combining your expertise in the target market with our knowledge of building products.</p>
				<p>Driven by innovation, we want to ensure that the product continues to iterate and innovate beyond its time with us. We do this by supporting and assisting the founders in transitioning to their own technical team.</p>
				</div>
			</div>
		</div>
  </Layout>
)

export default PurposePage
